import { SVGProps } from 'components/Icon'
import { FC } from 'react'

const ArrowForward: FC<SVGProps> = ({ className }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M8.66669 3.666C8.49602 3.666 8.32535 3.73067 8.19535 3.86067C8.06535 3.99133 8.00002 4.162 8.00002 4.33267V6.03867C5.00002 6.37067 2.66669 8.91133 2.66669 11.9993V12.666C4.16335 10.384 6.16669 10.0527 8.00002 10.0067V11.666C8.00002 11.836 8.06535 12.0073 8.19535 12.1373C8.32535 12.2673 8.49602 12.3327 8.66669 12.3327C8.83735 12.3327 9.00802 12.2673 9.13802 12.1373L13.3334 7.99933L9.13802 3.86067C9.00802 3.73067 8.83735 3.666 8.66669 3.666Z" />
    </svg>
)

export default ArrowForward
