import { ComponentProps, FC } from 'react'
import Icon from '../../Icon'
import styles from 'styles/components/Layout/Header/IconButton.module.sass'
import classNames from 'classnames'
import Link from 'next/link'

type IconButtonProps = {
    name: ComponentProps<typeof Icon>['name']
    label: string
    className?: string
    containerClassName?: string
    href?: string
    onClick?: () => void
    id?: string
}

const IconButton: FC<IconButtonProps> = ({
    name,
    className,
    containerClassName,
    href,
    id,
    onClick = () => {},
    label
}) =>
    href ? (
        <Link href={href}>
            <a
                id={id}
                className={classNames(styles.container, containerClassName)}
                aria-label={label}
            >
                <Icon
                    className={className ? className : styles.defaultIcon}
                    name={name}
                />
            </a>
        </Link>
    ) : (
        <button
            id={id}
            type="button"
            className={classNames(styles.container, containerClassName)}
            onClick={onClick}
            aria-label={label}
        >
            <Icon
                className={className ? className : styles.defaultIcon}
                name={name}
            />
        </button>
    )

export default IconButton
