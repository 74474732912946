import { ComponentProps, FC, Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import Link from 'next/link'

const Markdown: FC<ComponentProps<typeof ReactMarkdown>> = props => (
    <ReactMarkdown
        components={{
            p: Fragment,
            a: ({ href, children, target }) => (
                <Link href={href ?? ''}>
                    <a target={target}>{children}</a>
                </Link>
            )
        }}
        {...props}
    >
        {props.children}
    </ReactMarkdown>
)

export default Markdown
