import { FC, useId } from 'react'
import dynamic from 'next/dynamic'
import ArrowForward from './Icon/ArrowForward'

const Add = dynamic(() => import('./Icon/Add'))
const Avatar = dynamic(() => import('./Icon/Avatar'))
const AvatarGradient = dynamic(() => import('./Icon/AvatarGradient'))
const Chat = dynamic(() => import('./Icon/Chat'))
const Check = dynamic(() => import('./Icon/Check'))
const Close = dynamic(() => import('./Icon/Close'))
const Eye = dynamic(() => import('./Icon/Eye'))
const EyeInvisible = dynamic(() => import('./Icon/EyeInvisible'))
const Link = dynamic(() => import('./Icon/Link'))
const Menu = dynamic(() => import('./Icon/Menu'))
const MenuGradient = dynamic(() => import('./Icon/MenuGradient'))
const Notification = dynamic(() => import('./Icon/Notification'))
const Portfolio = dynamic(() => import('./Icon/Portfolio'))
const Search = dynamic(() => import('./Icon/Search'))
const VkLogo = dynamic(() => import('./Icon/VkLogo'))
const VkLogoGradient = dynamic(() => import('./Icon/VkLogoGradient'))
const VkLogoHover = dynamic(() => import('./Icon/VkLogoHover'))
const CheckCircle = dynamic(() => import('./Icon/CheckCircle'))
const UncheckCircle = dynamic(() => import('./Icon/UncheckCircle'))
const SimbiozLogo = dynamic(() => import('./Icon/SimbiozLogo'))
const SimbiozLogoGradient = dynamic(() => import('./Icon/SimbiozLogoGradient'))
const EmojiSmileGradient = dynamic(() => import('./Icon/EmojiSmileGradient'))
const ListGradient = dynamic(() => import('./Icon/ListGradient'))
const LockGradient = dynamic(() => import('./Icon/LockGradient'))
const EmojiSadGradient = dynamic(() => import('./Icon/EmojiSadGradient'))
const EmojiSurprisedGradient = dynamic(
    () => import('./Icon/EmojiSurprisedGradient')
)
const PersonalBrandIllustrationGradient = dynamic(
    () => import('./Icon/PersonalBrandIllustrationGradient')
)
const ResumeIllustrationGradient = dynamic(
    () => import('./Icon/ResumeIllustrationGradient')
)
const PortfolioIllustrationGradient = dynamic(
    () => import('./Icon/PortfolioIllustrationGradient')
)
const UnionIllustrationGradient = dynamic(
    () => import('./Icon/UnionIllustrationGradient')
)
const PFKILogo = dynamic(() => import('./Icon/PFKILogo'))
const HighFive = dynamic(() => import('./Icon/HighFive'))
const Pen = dynamic(() => import('./Icon/Pen'))
const Quote = dynamic(() => import('./Icon/Quote'))
const ArrowDown = dynamic(() => import('./Icon/ArrowDown'))
const Settings = dynamic(() => import('./Icon/Settings'))
const SendMesssage = dynamic(() => import('./Icon/SendMessage'))
const ArrowLeft = dynamic(() => import('./Icon/ArrowLeft'))

export type SVGProps = { className?: string; id: string }

const icons = {
    add: Add,
    avatar: Avatar,
    'avatar-gradient': AvatarGradient,
    chat: Chat,
    check: Check,
    close: Close,
    eye: Eye,
    'eye-invisible': EyeInvisible,
    link: Link,
    menu: Menu,
    'menu-gradient': MenuGradient,
    notification: Notification,
    portfolio: Portfolio,
    search: Search,
    vk: VkLogo,
    'vk-gradient': VkLogoGradient,
    'vk-hover': VkLogoHover,
    simbioz: SimbiozLogo,
    'simbioz-gradient': SimbiozLogoGradient,
    'check-circle': CheckCircle,
    'uncheck-circle': UncheckCircle,
    'emoji-smile-gradient': EmojiSmileGradient,
    'emoji-sad-gradient': EmojiSadGradient,
    'emoji-surprised-gradient': EmojiSurprisedGradient,
    'list-gradient': ListGradient,
    'lock-gradient': LockGradient,
    'personal-brand-illustration-gradient': PersonalBrandIllustrationGradient,
    'resume-illustration-gradient': ResumeIllustrationGradient,
    'portfolio-illustration-gradient': PortfolioIllustrationGradient,
    'union-illustration-gradient': UnionIllustrationGradient,
    'pfki-logo': PFKILogo,
    'high-five-gradient': HighFive,
    'pen-gradient': Pen,
    quote: Quote,
    settings: Settings,
    'arrow-down': ArrowDown,
    'send-message': SendMesssage,
    'arrow-left': ArrowLeft,
    'arrow-forward': ArrowForward
}

export type IconProps = { name: keyof typeof icons; className?: string }

const Icon: FC<IconProps> = ({ name, className = 'w-[100px] h-[100px]' }) => {
    const id = useId()

    const IconToRender = icons[name]

    return <IconToRender id={id} className={className} />
}

export default Icon
