import { FC, ReactNode } from 'react'
import styles from 'styles/components/Layout/Header/HeaderContainer.module.sass'
import classNames from 'classnames'

type HeaderContainerProps = {
    containerClassName?: string

    left?: ReactNode
    leftGroup?: ReactNode
    center?: ReactNode
    rightGroup?: ReactNode
    right?: ReactNode
}

const HeaderContainer: FC<HeaderContainerProps> = ({
    containerClassName,
    left,
    leftGroup,
    center,
    rightGroup,
    right
}) => (
    <header className={classNames(styles.header, containerClassName)}>
        <span className={classNames(styles.left, styles.sideElement)}>
            {left}
        </span>

        <span className={classNames(styles.group, styles.leftGroup)}>
            {leftGroup}
        </span>

        {center}

        <span className={classNames(styles.group, styles.rightGroup)}>
            {rightGroup}
        </span>

        <span className={classNames(styles.right, styles.sideElement)}>
            {right}
        </span>
    </header>
)

export default HeaderContainer
