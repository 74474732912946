import { FC } from 'react'
import styles from 'styles/components/Layout/Header.module.sass'
import Icon from '../Icon'
import IconButton from './Header/IconButton'
import NotificationsButton from './Header/NotificationsButton'
import HeaderContainer from './Header/HeaderContainer'
import Link from 'next/link'
import classNames from 'classnames'
import Avatar from 'components/Personal/Avatar'

export type Action = (() => void) | string

export const switchOnActionType = (action: Action) =>
    typeof action === 'string' ? { href: action } : { onClick: action }

type ProfileNotFilledActionSet = {
    profileNotFilled: true
    menuAction: Action
    profileAction: Action
    profileAvatarSrc?: string
}

type ActionSet = {
    profileNotFilled?: false

    menuAction: Action

    addAction: Action
    chatAction: Action
    unreadMessagesAmount?: number

    notificationsAction: ((amount: number) => void) | string
    notificationsAmount?: number
    searchAction: Action

    profileAction: Action
    profileAvatarSrc?: string
}

type LandingSet = {
    centralLink: { name: string; href: string }
    vkLink: string
}

type HeaderTitleOnlyProps = {
    title: string
    logoHref?: string | URL
    propSet?: undefined
}

type NormalHeaderProps = {
    title: string
    logoHref?: string | URL
    landing?: false
    propSet: ActionSet | ProfileNotFilledActionSet
}

type LandingHeaderProps = {
    title: string
    logoHref?: string | URL
    landing: true
    propSet: LandingSet
}

const Header: FC<
    (HeaderTitleOnlyProps | NormalHeaderProps | LandingHeaderProps) & {
        containerClassName?: string
    }
> = props => {
    const { title } = props

    const renderTitle = (className?: string, logoClassName?: string) => (
        <Link href={props.logoHref || ''} prefetch={false}>
            <a className={classNames(styles.a, styles.logoLink)}>
                <Icon
                    className={classNames(styles.simbiozLogo, logoClassName)}
                    name="simbioz-gradient"
                />
                <span className={classNames(styles.logoText, className)}>
                    {title}
                </span>
                <span className={styles.beta}>бета</span>
            </a>
        </Link>
    )

    const landingTitle = renderTitle(
        styles.logoTextLanding,
        styles.simbiozLogoLanding
    )

    if (!props.propSet) {
        return (
            <HeaderContainer
                containerClassName={classNames(
                    styles.container,
                    props.containerClassName
                )}
                center={landingTitle}
            />
        )
    }

    if (props.landing) {
        const { centralLink, vkLink } = props.propSet

        return (
            <HeaderContainer
                containerClassName={classNames(
                    styles.container,
                    props.containerClassName
                )}
                left={landingTitle}
                center={
                    <Link href={centralLink.href}>
                        <a
                            className={styles.centralLinkLanding}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {centralLink.name}
                        </a>
                    </Link>
                }
                right={
                    <Link href={vkLink}>
                        <a
                            className={styles.vkLinkContainer}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Перейти в сообщество ВКонтакте"
                        >
                            <Icon
                                className={styles.landingVkIcon}
                                name="vk-gradient"
                            />
                            <Icon
                                className={styles.landingVkIconHover}
                                name="vk-hover"
                            />
                        </a>
                    </Link>
                }
            />
        )
    }

    const { menuAction, profileAction, profileAvatarSrc } = props.propSet

    let addAction: Action,
        chatAction: Action,
        searchAction: Action,
        notificationsAction: string | ((amount: number) => void),
        notificationsAmount: number,
        unreadMessagesAmount: number

    const profileIsNotFilled = props.propSet.profileNotFilled

    return (
        <HeaderContainer
            containerClassName={classNames(
                styles.container,
                props.containerClassName
            )}
            left={
                <IconButton
                    className={styles.menuIcon}
                    containerClassName={styles.menuContainer}
                    name="menu-gradient"
                    {...switchOnActionType(menuAction)}
                    id="header-menu-button"
                    label="Меню"
                />
            }
            leftGroup={
                !profileIsNotFilled && (
                    <>
                        {
                            void ({
                                addAction,
                                chatAction,
                                unreadMessagesAmount = 0
                            } = props.propSet)
                        }
                        <IconButton
                            name="add"
                            {...switchOnActionType(addAction)}
                            label="Поделиться мыслью"
                        />
                        <NotificationsButton
                            amount={unreadMessagesAmount}
                            iconName="chat"
                            iconLabel="Чат"
                            action={chatAction}
                        />
                    </>
                )
            }
            center={renderTitle()}
            rightGroup={
                !profileIsNotFilled && (
                    <>
                        {
                            void ({
                                searchAction,
                                notificationsAction,
                                notificationsAmount = 0
                            } = props.propSet)
                        }
                        <NotificationsButton
                            amount={notificationsAmount}
                            action={
                                typeof notificationsAction === 'string'
                                    ? notificationsAction
                                    : () =>
                                          (
                                              notificationsAction as (
                                                  amount: number
                                              ) => void
                                          )(notificationsAmount)
                            }
                            iconName="notification"
                            iconLabel="Уведомления"
                        />

                        <IconButton
                            name="search"
                            {...switchOnActionType(searchAction)}
                            label="Поиск людей"
                        />
                    </>
                )
            }
            right={
                profileAvatarSrc !== undefined ? (
                    <Avatar
                        alt="Меню"
                        size="extra-small"
                        {...switchOnActionType(profileAction)}
                        src={profileAvatarSrc}
                    ></Avatar>
                ) : (
                    <IconButton
                        className={styles.avatarIcon}
                        containerClassName={styles.avatarContainer}
                        name="avatar-gradient"
                        {...switchOnActionType(profileAction)}
                        id="header-profile-button"
                        label="Меню"
                    />
                )
            }
        />
    )
}

export default Header
