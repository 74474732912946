import { ComponentProps, FC } from 'react'
import styles from '../../../styles/components/Layout/Header.module.sass'
import IconButton from './IconButton'
import { Action } from '../Header'
import { switchOnActionType } from '../Header'
import classNames from 'classnames'

type NotificationsButtonProps = {
    action?: Action
    amount: number
    iconName: ComponentProps<typeof IconButton>['name']
    iconLabel: string
    className?: string
}

const NotificationsButton: FC<NotificationsButtonProps> = ({
    amount,
    action = () => {},
    iconName,
    iconLabel,
    className
}) => (
    <div className={classNames(styles.notificationDiv, className)} {...switchOnActionType(action)}>
        <IconButton name={iconName} label={iconLabel} />
        {amount > 0 && (
            <span className={styles.notificationAmount}>
                {amount > 999 ? '999+' : amount}
            </span>
        )}
    </div>
)

export default NotificationsButton
