import Image, { StaticImageData } from 'next/future/image'
import classNames from 'classnames'
import styles from 'styles/components/Personal/Avatar.module.sass'
import { FC, MutableRefObject } from 'react'
import Link from 'next/link'
import GradientBorderContainer from '../Welcome/GradientBorderContainer'
import FrogPlaceholder from 'public/images/Frog.png'
import test from 'ramda/es/test'
import { isString } from 'ramda-adjunct'

type AvatarProps = {
    src?: string | StaticImageData | null
    alt: string
    size: keyof typeof sizeToPixels
    href?: string | null
    className?: string
    placeholderClassName?: string
    gradientBorder?: boolean
    avatarContainerRef?: MutableRefObject<
        HTMLAnchorElement | HTMLDivElement | null
    >
    priority?: boolean
    noBackground?: boolean
    onClick?: () => void
}

const sizeToPixels = {
    large: 120,
    medium: 80,
    small2: 60,
    small: 40,
    'extra-small': 32
}

const isYandexUrl = (url: unknown) =>
    isString(url) && test(/storage.yandexcloud.net/, url)

const Avatar: FC<AvatarProps> = ({
    src,
    alt,
    size,
    href,
    className,
    placeholderClassName,
    gradientBorder,
    avatarContainerRef,
    priority,
    noBackground = false,
    onClick
}) => {
    const ImageOrIcon = () =>
        src ? (
            gradientBorder ? (
                <GradientBorderContainer
                    className={styles.gradientBorder}
                    type="white"
                >
                    <Image
                        className={styles.image}
                        width={sizeToPixels[size]}
                        height={sizeToPixels[size]}
                        src={src}
                        alt={alt}
                        priority={priority}
                        unoptimized={isYandexUrl(src)}
                    />
                </GradientBorderContainer>
            ) : (
                <Image
                    className={classNames(
                        {
                            [styles.imageBackground ?? '']: !noBackground
                        },
                        styles.image
                    )}
                    width={sizeToPixels[size]}
                    height={sizeToPixels[size]}
                    src={src}
                    alt={alt}
                    priority={priority}
                    unoptimized={isYandexUrl(src)}
                />
            )
        ) : (
            <Image
                className={classNames(
                    styles.placeholderIcon,
                    placeholderClassName
                )}
                src={FrogPlaceholder}
                alt="Жабка"
            />
        )

    return href ? (
        <Link href={href} onClick={onClick}>
            <a
                ref={avatarContainerRef as any}
                className={classNames(
                    styles.imageContainer,
                    styles[size],
                    className,
                    { [styles.clickable ?? '']: onClick !== undefined }
                )}
            >
                <ImageOrIcon />
            </a>
        </Link>
    ) : (
        <div
            ref={avatarContainerRef as any}
            className={classNames(
                styles.imageContainer,
                styles[size],
                className,
                { [styles.clickable ?? '']: onClick !== undefined }
            )}
            onClick={onClick}
        >
            <ImageOrIcon />
        </div>
    )
}

Avatar.displayName = 'Avatar'
export default Avatar
