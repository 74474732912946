import {
    ButtonHTMLAttributes,
    ComponentProps,
    DetailedHTMLProps,
    FC,
    MouseEventHandler,
    PropsWithChildren
} from 'react'
import classNames from 'classnames'
import styles from 'styles/components/Button.module.sass'
import Link from 'next/link'

type ButtonProps = PropsWithChildren<{
    small?: boolean
    type?: 'primary' | 'secondary' | 'danger' | 'white'
    disabled?: boolean
    unstyledDisabled?: boolean
    className?: string
    bounded?: boolean
    submit?: boolean
}>

type NormalButtonProps = ButtonProps & {
    href?: undefined
    restProps?: Omit<
        DetailedHTMLProps<
            ButtonHTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
        >,
        'type' | 'onClick' | 'disabled' | 'className'
    >
    onClick?: MouseEventHandler<HTMLButtonElement>
}

type LinkButtonProps = ButtonProps & {
    href: string
    restProps?: Omit<ComponentProps<typeof Link>, 'href'>
    onClick?: MouseEventHandler<HTMLAnchorElement>
}

const Button: FC<NormalButtonProps | LinkButtonProps> = ({
    children,
    small = false,
    type = 'primary',
    disabled = false,
    unstyledDisabled = false,
    className = '',
    onClick,
    bounded,
    submit,
    href,
    restProps
}) =>
    href === undefined ? (
        <button
            type={submit ? 'submit' : 'button'}
            onClick={onClick}
            disabled={disabled || unstyledDisabled}
            className={classNames(
                styles.container,
                small ? styles.small : styles.large,
                styles[type],
                {
                    [styles.disabled ?? '']: disabled,
                    [styles.bounded ?? '']: bounded
                },
                className
            )}
            {...restProps}
        >
            {children}
        </button>
    ) : (
        <Link href={href} {...restProps}>
            <a
                onClick={onClick}
                className={classNames(
                    styles.container,
                    small ? styles.small : styles.large,
                    styles[type],
                    {
                        [styles.disabled ?? '']: disabled,
                        [styles.bounded ?? '']: bounded
                    },
                    styles.linkButton,
                    className
                )}
            >
                {children}
            </a>
        </Link>
    )

export default Button
