import { FC, PropsWithChildren } from 'react'
import styles from 'styles/components/Welcome/GradientBorderContainer.module.sass'
import classNames from 'classnames'

type GradientBorderContainerProps = PropsWithChildren<{
    type?: 'gray' | 'white' | 'light' | 'dark' | 'mySky'
    flip?: boolean
    className?: string
}>

const GradientBorderContainer: FC<GradientBorderContainerProps> = ({
    type = 'gray',
    flip = false,
    children,
    className
}) => (
    <div
        className={classNames(
            styles.container,
            styles[type],
            {
                [styles.flip ?? '']: flip
            },
            className
        )}
    >
        {children}
    </div>
)

export default GradientBorderContainer
